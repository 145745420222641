(function()
{
	'use strict';
	
	jQuery(function()
	{
		jQuery('#slider-container').owlCarousel(
		{
			items: 1,
			autoplay: true,
			autoplayTimeout: 5000,
			autoplayHoverPause: true,
			nav: true,
			navText: ['<i class="mdi mdi-chevron-left"></i>', '<i class="mdi mdi-chevron-right"></i>'],
			dots: false,
			pullDrag: false,
			navSpeed: 250,
			navRewind: false,
			mouseDrag: false,
			loop: true
		});
	});
}());